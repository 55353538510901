<div>
  <h2>{{ 'Administration::AddOnAIFeatureSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
@if (form) {
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="mt-2 fade-in-top col-6">
      <div class="form-group">
        <label for="llmConfiguration" class="pt-3"
          >{{
            'Administration::AddOnAIFeatureSettings:LlmConfiguration' | abpLocalization
          }}
          *</label
        >
        <ng-select
          id="llmConfiguration"
          formControlName="llmConfiguration"
          (change)="setSelectedLlmConfiguration($event)"
        >
          @for (llmConfigurationOption of llmConfigurationOptions; track llmConfigurationOption) {
            <ng-option [value]="llmConfigurationOption.id">
              {{ llmConfigurationOption.name }}
            </ng-option>
          }
        </ng-select>

        @if (useModelOptions) {
          <label for="model" class="pt-3"
            >{{ 'Administration::AddOnAIFeatureSettings:Model' | abpLocalization }} *</label
          >
          <ng-select id="model" formControlName="model" (change)="setSelectedModel()">
            @for (modelOption of modelOptions; track modelOption) {
              <ng-option [value]="modelOption">
                {{ modelOption }}
              </ng-option>
            }
          </ng-select>
        }

        @if (isCustomModelSelected) {
          <label for="modelName" class="pt-3">
            {{ 'Administration::AddOnAIFeatureSettings:ModelName' | abpLocalization }} *
          </label>
          <input
            type="text"
            id="modelName"
            formControlName="modelName"
            class="form-control"
            placeholder="{{
              'Administration::AddOnAIFeatureSettings:ModelNamePlaceholder' | abpLocalization
            }}"
          />
        }
      </div>
    </div>
    <abp-button
      (click)="submitForm()"
      iconClass="fa fa-check"
      [loading]="isLoading"
      [disabled]="!isFormValid()"
      class="ms-1"
    >
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </form>
}
