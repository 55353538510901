import { ConfigStateService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomSettingsService } from '../proxy/custom-settings.service';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  ADD_ON_AI_FATURE_MODEL,
  ADD_ON_AI_FEATURE_LLM_CONFIGURATION,
  ADD_ON_AI_FEATURE_MODEL_NAME,
} from './addon-ai-features-settings.consts';
import { CustomSettingsDto } from '../proxy/custom-settings.model';
import { AddOnAIFeaturesSettingsService } from './proxy/addon-ai-features-settings.service';
import { LlmConfigurationProvider } from 'src/app/integrations/proxy/integration.model';
@Component({
  selector: 'cai-addon-ai-features-settings',
  templateUrl: './addon-ai-features-settings.component.html',
  styleUrls: ['./addon-ai-features-settings.component.scss'],
})
export class AddOnAIFeaturesSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  llmConfigurationOptions;
  modelOptions;
  selectedLlmConfigurationId: string;
  selectedLlmConfiguration;
  selectedModel: string;
  isCustomModelSelected = false;
  useModelOptions = true;
  modelNameMaxLength = 200;

  constructor(
    private config: ConfigStateService,
    private fb: UntypedFormBuilder,
    private settingsService: CustomSettingsService,
    private toaster: ToasterService,
    private addOnAIFeaturesSettingsService: AddOnAIFeaturesSettingsService,
  ) {}

  ngOnInit(): void {
    this.addOnAIFeaturesSettingsService.getAddOnAiFeatureSettingOptions().subscribe((options) => {
      this.llmConfigurationOptions = options.llmConfigurationSettings.filter(
        (setting) =>
          setting.provider === LlmConfigurationProvider.openAi ||
          setting.provider === LlmConfigurationProvider.azureOpenAi,
      );

      this.buildForm();
    });
  }

  buildForm() {
    this.selectedLlmConfigurationId = this.config.getSetting(ADD_ON_AI_FEATURE_LLM_CONFIGURATION);
    this.getModelOptions();

    this.form = this.fb.group({
      llmConfiguration: [this.selectedLlmConfigurationId, [Validators.required]],
      model: [
        this.config.getSetting(ADD_ON_AI_FATURE_MODEL).trim() === ''
          ? this.modelOptions?.length
            ? this.modelOptions[0]
            : ''
          : this.config.getSetting(ADD_ON_AI_FATURE_MODEL),
        [Validators.required],
      ],
      modelName: [
        this.config.getSetting(ADD_ON_AI_FEATURE_MODEL_NAME),
        [Validators.maxLength(this.modelNameMaxLength)],
      ],
    });

    this.selectedModel = this.form.get('model').value;
    this.checkModelSelection();
    this.checkCustomModelSelection();
  }

  submitForm() {
    if (!this.isFormValid()) {
      return;
    }

    const settings: CustomSettingsDto[] = [];
    settings.push(
      {
        name: ADD_ON_AI_FEATURE_LLM_CONFIGURATION,
        value: this.form.get('llmConfiguration').value,
      },
      {
        name: ADD_ON_AI_FATURE_MODEL,
        value: this.form.get('model').value,
      },
      {
        name: ADD_ON_AI_FEATURE_MODEL_NAME,
        value: this.form.get('modelName').value,
      },
    );

    this.settingsService.save(settings).subscribe({
      next: () => {
        this.toaster.success('::CustomSettings:Save:Success');
        this.buildForm();
      },
      error: (error) => {
        const businessExceptionErrorCodePrefix = 'Raven:';

        // If the error is caused by a business exception, then the related error message will be shown
        // via global error handling popup, so no need to display an additional error toaster here.
        if (!error?.error?.error?.code.startsWith(businessExceptionErrorCodePrefix)) {
          this.toaster.error('::CustomSettings:Save:Error');
          console.log(error);
        }
      },
    });
  }

  setSelectedLlmConfiguration(id: string) {
    this.selectedLlmConfigurationId = id;
    this.getModelOptions();
    this.form.patchValue({
      model: this.modelOptions[0],
    });
    this.isCustomModelSelected = false;
    this.checkModelSelection();
    this.checkCustomModelSelection();
  }

  setSelectedModel() {
    this.selectedModel = this.form.get('model').value;
    this.checkCustomModelSelection();
  }

  isFormValid() {
    return (
      this.form?.get('model')?.valid &&
      this.form?.get('llmConfiguration')?.valid &&
      this.form?.get('modelName')?.valid
    );
  }

  checkModelSelection() {
    if (!this.selectedLlmConfiguration) {
      return;
    }
    if (!(this.selectedLlmConfiguration.models.length > 0)) {
      this.useModelOptions = false;
      this.form.get('model').reset();
      this.form.get('model').clearValidators();
      this.form.get('model').updateValueAndValidity();
    }
  }

  checkCustomModelSelection() {
    if (!this.selectedLlmConfiguration) {
      return;
    }
    const hasModels = this.selectedLlmConfiguration.models.length > 0;
    this.isCustomModelSelected = hasModels && this.selectedModel === 'custom model';
    if (this.isCustomModelSelected) {
      this.form
        .get('modelName')
        .setValidators([Validators.required, Validators.maxLength(this.modelNameMaxLength)]);
    } else {
      this.form.get('modelName').reset();
      this.form.get('modelName').clearValidators();
      this.form.get('modelName').updateValueAndValidity();
    }
  }
  getModelOptions() {
    if (!this.selectedLlmConfigurationId) {
      return;
    }
    this.selectedLlmConfiguration = this.llmConfigurationOptions.find(
      (x) => x.id === this.selectedLlmConfigurationId,
    );
    if (this.selectedLlmConfiguration.models.length > 0) {
      this.modelOptions = this.selectedLlmConfiguration.models;
      this.useModelOptions = true;
    } else {
      this.useModelOptions = false;
    }
  }
}
