import {
  AuthService,
  ConfigStateService,
  PermissionService,
  ReplaceableComponentsService,
  RoutesService,
} from '@abp/ng.core';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderBarVisibilityService } from '@proxy/loader-bar-visibility.service';
import {
  PremiumReportingAddMenuService,
  PremiumReportingConfigService,
  PremiumReportingMenuService,
} from '@sestek/premium-reporting/config';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { BotHubSettingsComponent } from './settings/bothub-settings/bothub-settings.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TOOLTIP_DELAY } from './shared/shared.consts';
import { CaIntegrationSettingsComponent } from './settings/ca-integration-settings/ca-integration-settings.component';
import { NotificationService } from '@sestek/notification';
import { FooterComponent } from './shared/footer/footer.component';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { AddOnAIFeaturesSettingsComponent } from './settings/addon-ai-features-settings/addon-ai-features-settings.component';
import {
  AI_FEATURE_CONVERSATION_SUMMARIZATION,
  AI_FEATURE_GENERATIVE_QUESTION_ANSWERING,
  AI_FEATURE_LIVECHAT_SUMMARIZATION,
  AI_FEATURE_TEXT_RESPONSE_GENERATION,
  AI_FEATURE_UTTERANCE_GENERATION,
} from './virtual-agents/proxy/open-ai/open-ai.const';
import { LogoComponent } from './shared/nav-items/logo/logo.component';
import { NavigationEnd, Router } from '@angular/router';
import { RoutesComponent } from './shared/routes/cai-routes/cai-routes.component';
import { ToasterService } from '@abp/ng.theme.shared';
import { RedactionSettingsComponent } from './settings/redaction-settings/redaction-settings.component';
import { OutboundConversationSettingsComponent } from './settings/outbound-conversation-settings/outbound-conversation-settings.component';
import { OUTBOUND_FEATURE_OUTBOUND_CONVERSATIONS } from './projects/proxy/outbound/outbound-rules.const';
import { GenerativeQnaSettingsSettingsComponent } from './settings/generative-qna-settings/generative-qna-settings.component';

@Component({
  selector: 'cai-root',
  template: `
    @if (isLoaderVisible) {
      <abp-loader-bar></abp-loader-bar>
    }
    <abp-dynamic-layout></abp-dynamic-layout>
    <cai-column-configurator></cai-column-configurator>
  `,
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoaderVisible = true;
  loaderBarVisibilitySubscription: Subscription;

  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private loaderBarVisibilityService: LoaderBarVisibilityService,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private settingTabs: SettingTabsService,
    private premiumReportingConfigService: PremiumReportingConfigService,
    private premiumReportingMenuService: PremiumReportingMenuService,
    private premiumReportingAddMenuService: PremiumReportingAddMenuService,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private config: ConfigStateService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private router: Router,
    private routes: RoutesService,
    private toaster: ToasterService,
  ) {
    Date.prototype.toJSON = function () {
      const copiedDate = new Date(this.getTime());
      const result = copiedDate.toISOString();
      return result;
    };
    this.ngbTooltipConfig.openDelay = TOOLTIP_DELAY;

    this.notificationService.initialize();
  }

  get hasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const navigationState = this.router.getCurrentNavigation().extras.state;
        if (!navigationState?.persistToaster) {
          this.toaster.clear();
        }
      }
    });

    const isPremiumReportingFeatureEnabled = this.config.getFeature(
      'PremiumReportingGroup.PremiumReporting',
    );
    if (
      this.permissionService.getGrantedPolicy('PremiumReporting.PremiumReports') &&
      isPremiumReportingFeatureEnabled === 'true'
    ) {
      this.getPremiumReportingMenuList();
      this.premiumReportingMenuService.premiumReportingMenuChange.subscribe(() =>
        this.getPremiumReportingMenuList(),
      );
    }
    this.settingTabs.add([
      {
        name: '::BotHubSettings',
        order: 3,
        component: BotHubSettingsComponent,
        requiredPolicy: 'BotHub.SettingManagement',
      },
      {
        name: 'Administration::CaIntegrationSettings',
        order: 4,
        component: CaIntegrationSettingsComponent,
        requiredPolicy: 'AbpIdentity.SettingManagement',
      },
    ]);

    const isGenerativeQAFeatureEnabled =
      this.config.getFeature(AI_FEATURE_GENERATIVE_QUESTION_ANSWERING) === 'true';

    if (isGenerativeQAFeatureEnabled) {
      this.settingTabs.add([
        {
          name: 'Administration::GenerativeQuestionAnsweringSettings',
          order: 5,
          component: GenerativeQnaSettingsSettingsComponent,
          requiredPolicy: 'AbpIdentity.SettingManagement',
        },
      ]);
    }

    const isAddOnAiFeaturesEnabled =
      this.config.getFeature(AI_FEATURE_CONVERSATION_SUMMARIZATION) === 'true' ||
      this.config.getFeature(AI_FEATURE_UTTERANCE_GENERATION) === 'true' ||
      this.config.getFeature(AI_FEATURE_LIVECHAT_SUMMARIZATION) === 'true' ||
      this.config.getFeature(AI_FEATURE_TEXT_RESPONSE_GENERATION) === 'true';

    const isLlmConfigurationPermissionGranted = this.permissionService.getGrantedPolicy(
      'Designer.LlmConfigurations',
    );
    if (isAddOnAiFeaturesEnabled && isLlmConfigurationPermissionGranted) {
      this.settingTabs.add([
        {
          name: 'Administration::AddOnAIFeatureSettings',
          order: 6,
          component: AddOnAIFeaturesSettingsComponent,
          requiredPolicy: 'AbpIdentity.SettingManagement',
        },
      ]);
    }

    this.settingTabs.add([
      {
        name: 'Administration::RedactionSettings',
        order: 7,
        component: RedactionSettingsComponent,
        requiredPolicy: 'AbpIdentity.SettingManagement',
      },
    ]);

    const isOutboundConversationsFeatureEnabled =
      this.config.getFeature(OUTBOUND_FEATURE_OUTBOUND_CONVERSATIONS) === 'true';

    if (isOutboundConversationsFeatureEnabled) {
      this.settingTabs.add([
        {
          name: 'Administration::OutboundSettings',
          order: 8,
          component: OutboundConversationSettingsComponent,
          requiredPolicy: 'AbpIdentity.SettingManagement',
        },
      ]);
    }

    if (!this.hasLoggedIn) {
      this.authService.navigateToLogin();
    }

    this.replaceableComponents.add({
      component: FooterComponent,
      key: eThemeLeptonComponents.ApplicationLayoutFooter,
    });

    this.replaceableComponents.add({
      component: LogoComponent,
      key: eThemeLeptonComponents.Logo,
    });

    this.replaceableComponents.add({
      component: RoutesComponent,
      key: eThemeLeptonComponents.Routes,
    });

    this.loaderBarVisibilitySubscription =
      this.loaderBarVisibilityService.loaderBarVisibilityChanged.subscribe((isVisible: boolean) => {
        this.isLoaderVisible = isVisible;
      });
  }

  getPremiumReportingMenuList() {
    this.premiumReportingConfigService.get({ maxResultCount: 50 }).subscribe((res) => {
      this.premiumReportingAddMenuService.addMenu(res.items);
      const prMenu = {
        order: 7,
      };
      this.routes.patch('PremiumReporting::Menu:PremiumReports', prMenu);
    });
  }

  ngOnDestroy() {
    this.loaderBarVisibilitySubscription.unsubscribe();
  }
}
