import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { AddOnAiFeatureSettingsLlmConfigurationDto } from './addon-ai-features-settings.model';

@Injectable({
  providedIn: 'root',
})
export class AddOnAIFeaturesSettingsService {
  apiName = 'Default';

  getAddOnAiFeatureSettingOptions = () =>
    this.restService.request<void, AddOnAiFeatureSettingsLlmConfigurationDto>(
      {
        method: 'GET',
        url: '/api/app/llm-configurations/ai-feature-settings',
      },
      { apiName: this.apiName },
    );

  getAddOnAiFeatureSettingUsageStatus = (id: string) =>
    this.restService.request<string, boolean>(
      {
        method: 'GET',
        url: `/api/app/llm-configurations/ai-feature-setting-usage-status/${id}`,
      },
      { apiName: this.apiName },
    );

  constructor(private restService: RestService) {}
}
