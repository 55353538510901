<div>
  <h2>{{ 'Project::GenerativeQnA' | abpLocalization }}</h2>
</div>
<br />
<hr />
@if (form) {
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="mt-2 fade-in-top">
      <div class="form-group">
        <label for="provider" class="pt-3"
          >{{
            'Administration::GenerativeQuestionAnsweringSettings:Provider' | abpLocalization
          }}
          *</label
        >
        <ng-select id="provider" formControlName="provider" (change)="setSelectedProvider()">
          @for (providerOption of providerOptions; track providerOption) {
            <ng-option [value]="providerOption">
              {{ providerOption }}
            </ng-option>
          }
        </ng-select>
      </div>
      <div class="form-group">
        <label for="apiKey"
          >{{ 'Administration::GenerativeQuestionAnsweringSettings:APIKey' | abpLocalization }}
          @if (!hasExistingApiKey) {
            <span>*</span>
          }
        </label>
        <input
          id="apiKey"
          formControlName="apiKey"
          class="form-control"
          type="password"
          autocomplete="new-password"
          [placeholder]="
            hasExistingApiKey
              ? ('Administration::GenerativeQuestionAnsweringSettings:APIKeyPlaceHolder'
                | abpLocalization)
              : ''
          "
        />
      </div>
      @if (isOpenAiSelected()) {
        <div class="form-group">
          <label for="model"
            >{{
              'Administration::GenerativeQuestionAnsweringSettings:Model' | abpLocalization
            }}
            *</label
          >
          <ng-select id="model" formControlName="model">
            @for (modelOption of modelOptions; track modelOption) {
              <ng-option [value]="modelOption">
                {{ modelOption }}
              </ng-option>
            }
          </ng-select>
        </div>
      }
      @if (isAzureOpenAiSelected()) {
        <div class="form-group">
          <label for="deploymentName"
            >{{
              'Administration::GenerativeQuestionAnsweringSettings:DeploymentName' | abpLocalization
            }}
            *</label
          >
          <input
            id="deploymentName"
            formControlName="deploymentName"
            class="form-control"
            type="text"
          />
        </div>
      }
      @if (isAzureOpenAiSelected()) {
        <div class="form-group">
          <label for="resourceName"
            >{{
              'Administration::GenerativeQuestionAnsweringSettings:ResourceName' | abpLocalization
            }}
            *</label
          >
          <input
            id="resourceName"
            formControlName="resourceName"
            class="form-control"
            type="text"
          />
        </div>
      }
      @if (isAzureOpenAiSelected()) {
        <div class="form-group">
          <label for="embeddingDeployment"
            >{{
              'Administration::GenerativeQuestionAnsweringSettings:EmbeddingDeployment'
                | abpLocalization
            }}
            @if (isGenerativeQuestionAnsweringFeatureEnabled) {
              <span>*</span>
            }
          </label>
          <input
            id="embeddingDeployment"
            formControlName="embeddingDeployment"
            class="form-control"
            type="text"
          />
        </div>
      }
      <div class="form-group">
        <label for="embeddingName">
          {{
            (isOpenAiSelected()
              ? 'Administration::GenerativeQuestionAnsweringSettings:EmbeddingNameForOpenAi'
              : 'Administration::GenerativeQuestionAnsweringSettings:EmbeddingNameForAzureOpenAi'
            ) | abpLocalization
          }}
          @if (isGenerativeQuestionAnsweringFeatureEnabled) {
            <span>*</span>
          }
        </label>
        <ng-select id="embeddingName" formControlName="embeddingName">
          @for (embeddingNameOption of embeddingNameOptions; track embeddingNameOption) {
            <ng-option [value]="embeddingNameOption">
              {{ embeddingNameOption }}
            </ng-option>
          }
        </ng-select>
      </div>
      <span
        ><b class="mb-2"
          >{{
            'Administration::GenerativeQuestionAnsweringSettings:Advanced:Settings'
              | abpLocalization
          }}
          <span class="arrow" (click)="toggleAdvancedSettings()">
            <i [ngClass]="'fas fa-caret-' + (isAdvancedSettingsOpen ? 'up' : 'down')"></i> </span
        ></b>
      </span>
      @if (isAdvancedSettingsOpen) {
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="maxTokens">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:OpenAiMaxTokens'
                    | abpLocalization
                }}
              </label>
              <input
                type="number"
                id="maxTokens"
                formControlName="maxTokens"
                class="form-control"
                autocomplete="nope"
              />
              <small class="text-muted switch-info-text-size">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:OpenAiMaxTokens:Description'
                    | abpLocalization
                }}
              </small>
            </div>
            <div class="form-group">
              <label for="chunkSize">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:SplitChunkSize'
                    | abpLocalization
                }}
              </label>
              <input
                type="number"
                id="chunkSize"
                formControlName="chunkSize"
                class="form-control"
                autocomplete="nope"
              />
              <small class="text-muted switch-info-text-size">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:SplitChunkSize:Description'
                    | abpLocalization
                }}
              </small>
            </div>
            <div class="form-group">
              <label for="chunkOverlap">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:SplitChunkOverlap'
                    | abpLocalization
                }}
              </label>
              <input
                type="number"
                id="chunkOverlap"
                formControlName="chunkOverlap"
                class="form-control"
                autocomplete="nope"
              />
              <small class="text-muted switch-info-text-size">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:SplitChunkOverlap:Description'
                    | abpLocalization
                }}
              </small>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="splitCount">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:RetrievedDocumentSplitCount'
                    | abpLocalization
                }}
              </label>
              <input
                type="number"
                id="splitCount"
                formControlName="splitCount"
                class="form-control"
                autocomplete="nope"
              />
              <small class="text-muted switch-info-text-size">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:RetrievedDocumentSplitCount:Description'
                    | abpLocalization
                }}
              </small>
            </div>
            <div class="form-group">
              <label for="llmProviderTokenPerMin">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:LlmProviderTokenPerMin'
                    | abpLocalization
                }}
              </label>
              <input
                type="number"
                id="llmProviderTokenPerMin"
                formControlName="llmProviderTokenPerMin"
                class="form-control"
                autocomplete="nope"
              />
              <small class="text-muted switch-info-text-size">
                <span
                  [innerHTML]="
                    'Administration::GenerativeQuestionAnsweringSettings:Advanced:LlmProviderTokenPerMin:Description'
                      | abpLocalization
                  "
                >
                </span>
              </small>
            </div>
            <div class="form-group">
              <label for="crawlerType">{{
                'Administration::GenerativeQuestionAnsweringSettings:Advanced:CrawlerType'
                  | abpLocalization
              }}</label>
              <ng-select id="crawlerType" formControlName="crawlerType">
                @for (crawlerTypeOption of crawlerTypeOptions; track crawlerTypeOption) {
                  <ng-option [value]="crawlerTypeOption.value">
                    <span [title]="crawlerTypeOption.tooltip">{{ crawlerTypeOption.name }}</span>
                  </ng-option>
                }
              </ng-select>
              <small class="text-muted switch-info-text-size">
                {{
                  'Administration::GenerativeQuestionAnsweringSettings:Advanced:CrawlerType:Description'
                    | abpLocalization
                }}
              </small>
            </div>
          </div>
        </div>
      }
    </div>
    <abp-button
      (click)="submitForm()"
      iconClass="fa fa-check"
      [loading]="isLoading"
      [disabled]="!isFormValid()"
      class="ms-1"
    >
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </form>
}
